<template>
  <sign-page
    title-text="采购物品"
    :request="request"
    table-size="small"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    @dataUpdate="dataUpdate"
    :deal-form-data="dealFormData"
    :form-parms-update="formParmsUpdate"
    :table-actions-fixed="true"
    :tableActionsWidth="100"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  goodsRequest as request
} from '../../api'

import AssetsTypeForm from './assets_type/assetsTypeForm'

export default {
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          label: '名称',
          key: 'name',
          type: 'input'
        },
        {
          type: 'cmp',
          label: '资产类型',
          key: 'assetsTypeId',
          cmp: AssetsTypeForm,
          check: {
            required: true
          }
        },
        {
          label: '是否限价',
          key: 'shiXj',
          selectDatas: [{key: 'yes', label: '是'}, { key: 'no', label: '否'}],
          type: 'select',
          check: {
            required: true
          }
        },
        {
          label: '限价金额',
          key: 'maxPirce',
          type: 'input',
          show (data) {
            return data.shiXj === 'yes'
          },
          check: {
            required: true,
            rules: {
              type: 'number'
            }
          }
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '名称',
          sort: true,
          field: 'name'
        },
        {
          title: '资产类型名称',
          sort: true,
          field: 'assetsTypeName'
        },
        {
          title: '资产类型编码',
          sort: true,
          field: 'assetsTypeCode'
        },
        {
          title: '是否限价',
          sort: true,
          field: 'shiXjText'
        },
        {
          title: '限价金额',
          sort: true,
          field: 'maxPirce'
        }]
        return data
      }
    }
  },
  methods: {
    dealFormData (data) {
      if (data.shiXj !== 'yes') {
        data.maxPirce = null
      }
      return data
    },
    dataUpdate () {
      this.$store.dispatch('loadGoodsList', true)
    },
    async loadData () {
      let data = await request.get()
      data.forEach(v => {
        v.shiXj = v.maxPirce === null ? 'no' : 'yes'
        v.shiXjText = v.shiXj === 'yes' ? '是' : '否'
      })
      return data
    }
  },
  created () {
    if (this.$authFunsProxy.update || this.$authFunsProxy.add) {
      this.$store.dispatch('loadAssetsTypeList')
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
